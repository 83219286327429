.slick-dots,.slick-next,.slick-prev{position:absolute;display:block;padding:0}.slick-dots li button:before,.slick-next:before,.slick-prev:before{font-family:slick;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}.slick-loading .slick-list{background:url(../images/ajax-loader.gif) center center 
    no-repeat #fff}@font-face{font-family:slick;font-weight:400;font-style:normal;src:url(../fonts/slick.eot);
        src:url(../fonts/slick.eot?#iefix) format('embedded-opentype'),url(../fonts/slick.woff) format('woff'),url(../fonts/slick.ttf) format('truetype'),url(../fonts/slick.svg#slick) format('svg')}.slick-next,.slick-prev{font-size:0;line-height:0;top:50%;width:20px;height:20px;-webkit-transform:translate(0,-50%);-ms-transform:translate(0,-50%);transform:translate(0,-50%);cursor:pointer;color:transparent;border:none;outline:0;background:0 0}.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{color:transparent;outline:0;background:0 0}.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{opacity:1}.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{opacity:.25}.slick-next:before,.slick-prev:before{font-size:20px;line-height:1;opacity:.75;color:#fff}.slick-prev{left:-25px}[dir=rtl] .slick-prev{right:-25px;left:auto}.slick-prev:before{content:'←'}.slick-next:before,[dir=rtl] .slick-prev:before{content:'→'}.slick-next{right:-25px}[dir=rtl] .slick-next{right:auto;left:-25px}[dir=rtl] .slick-next:before{content:'←'}.slick-dotted.slick-slider{margin-bottom:30px}.slick-dots{bottom:-25px;width:100%;margin:0;list-style:none;text-align:center}.slick-dots li{position:relative;display:inline-block;width:20px;height:20px;margin:0 5px;padding:0;cursor:pointer}.slick-dots li button{font-size:0;line-height:0;display:block;width:20px;height:20px;padding:5px;cursor:pointer;color:transparent;border:0;outline:0;background:0 0}.slick-dots li button:focus,.slick-dots li button:hover{outline:0}.slick-dots li button:focus:before,.slick-dots li button:hover:before{opacity:1}.slick-dots li button:before{font-size:6px;line-height:20px;position:absolute;top:0;left:0;width:20px;height:20px;content:'•';text-align:center;opacity:.25;color:#000}.slick-dots li.slick-active button:before{opacity:.75;color:#000}


/* ****************POUR ANIMATION DU LOGO*************** */
  .image-container {
    position: relative;
    display: inline-block; /* ou 'block', selon la disposition souhaitée */
  }
  
  .image-container img {
    height: 50px;
    width: 100px;
    display: block; /* Cela garantit que l'image ne sera pas à la ligne */
  }
  
  #animation_ {
    position: absolute;
    top: 50%; /* Ajustez en fonction de la hauteur de l'espace noir */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, -50%);
    color: white; /* Pour une meilleure visibilité sur fond noir */
    font-size: 10px; /* Réduisez la taille de la police selon le nécessaire */
    width: 100%; /* Assurez-vous que le conteneur s'étend sur toute la largeur de l'image */
    text-align: center; /* Centre le texte dans le conteneur */
  }


  /* ***************POUR LES IMAGES DE NOS SERVICES**************** */
  .service-details .additional-image-item {
    text-align: center; /* Centre le contenu */
    margin-bottom: 20px; /* Espacement entre les éléments du carrousel */
  }
  
  .service-details .additional-image-item img {
    height: 90px; /* Hauteur fixe pour toutes les images */
    width: 90px; /* Largeur fixe pour toutes les images */
    border-radius: 50%; /* Rend l'image ronde */
    object-fit: cover; /* Assure que l'image couvre bien l'espace sans être déformée */
    margin: 0 auto; /* Centre l'image horizontalement */
    display: block; /* Assure que l'image est traitée comme un bloc pour le centrage */
  }
  
  .service-details h5,
  .service-details p {
    margin-top: 10px; /* Espacement entre l'image et le texte */
  }
  
  /* ********POUR ACTIVER UN MENU DU NAVIGATEUR QUAND ON CLIQUE********** */
  .active-link {
    /* color: rgb(57, 14, 227); */
      color: var(--white-color);
    background-color: rgba(0, 0, 0, 0.1);
  }


  /* *************POUR LA SIDEBAR*************************** */
 /* SideCategories.css */
 .sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
  z-index: 1001; /* Assurez-vous que cela s'affiche au-dessus de tout le reste */
  color: #fff; /* Couleur de l'icône */
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background-color: #333;
  padding-top: 60px; /* Espace pour le logo et le bouton de fermeture */
  transition: transform 0.3s ease;
  transform: translateX(-100%);
  z-index: 1000; /* Assurez-vous que la sidebar est bien au-dessus du contenu de la page */
}

.sidebar.open {
  transform: translateX(0);
}

.category-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-list li a {
  display: block;
  padding: 10px 20px;
  color: #fff; /* Couleur du texte des liens */
  text-decoration: none;
  transition: background-color 0.2s ease;
}

.category-list li a:hover, .category-list li a.active {
  background-color: #1a1a1a; /* Couleur de fond au survol ou actif */
}


/* Autres styles */

/* ***********POUR LE PROFIL***************************** */
.user-profile-container {
  max-width: 800px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  margin-bottom: 15px;
}

.profile-balance {
  background-color: #1a1a1a;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.blockchain-address {
  background-color: #333;
  padding: 15px;
  /* text-align: center; */
}

.blockchain-address button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  margin-top: 10px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
}

.blockchain-address button:hover {
  background-color: #0056b3;
}

.blockchain-address svg {
  margin-right: 5px;
}

/* ************POUR LE FORMULAIRE DE LA PARTIE CONTACT*********** */
/* Appliquez ces styles dans votre fichier CSS */
.form-style {
  color: #fff; /* Couleur du texte */
  background-color: #333; /* Couleur de fond du formulaire */
  padding: 20px; /* Espacement autour du formulaire */
  border-radius: 8px; /* Bords arrondis */
}

.form-style input[type="text"],
.form-style input[type="email"],
.form-style textarea {
  background-color: #222; /* Couleur de fond des champs */
  border: 1px solid #555; /* Bordure des champs */
  color: #ddd; /* Couleur du texte dans les champs */
  padding: 10px; /* Espacement à l'intérieur des champs */
  width: 100%; /* Largeur complète */
  box-sizing: border-box; /* Pour inclure la bordure dans la largeur */
  margin-bottom: 10px; /* Espacement entre les champs */
}

.form-style label {
  display: block; /* Les labels prennent toute la largeur */
  margin-bottom: 5px; /* Espacement au-dessus des champs */
}

.form-style .checkbox-group label {
  margin-left: 5px; /* Espacement à gauche du texte du checkbox */
}

.form-style .btn-default {
  background-color: #007bff; /* Couleur de fond du bouton */
  color: #fff; /* Couleur du texte du bouton */
  border: none; /* Aucune bordure */
  padding: 10px 20px; /* Espacement à l'intérieur du bouton */
  cursor: pointer; /* Curseur en forme de main */
  border-radius: 5px; /* Bords arrondis du bouton */
}

.form-style .btn-default:hover {
  background-color: #0056b3; /* Couleur de fond du bouton au survol */
}

.form-style .success-message {
  color: #4CAF50; /* Couleur du message de succès */
  margin-top: 10px; /* Espacement au-dessus du message */
}

/* *********EMPECHER LA CAPTURE D'ECRAN********** */
bodyNo {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
/* ***********FIN************************************* */


/* ***************POUR LOADING**************** *********/
/* components/wti/sLoading.css */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* ***********FIN************************************* */


